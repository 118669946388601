import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="R" transform="translate(-45.000000, -85.000000)">
        <path
          d="M81.1063,113.4557 81.1063,154.7293 86.5946,154.7293 86.5946,138.3196 94.328,138.3196 108.1321,154.7293 115.2556,154.7293 99.7331,136.6842C102.3572 135.6679,104.4175 134.162,105.9144 132.1662,107.4112 130.152,108.1595 127.657,108.1595 124.6818,108.1595 122.4828 107.7345,120.6723 106.8845,119.2494 106.0529,117.808 104.8889,116.6157 103.3921,115.6733 102.0616,114.8417 100.565,114.2687 98.9018,113.9546 97.2387,113.622 95.1596,113.4557 92.6649,113.4557L81.1063,113.4557ZM86.5946,118.1683 93.0529,118.1683C94.5312,118.1683 95.8157,118.2788 96.9059,118.5005 97.9962,118.7038 98.9385,119.0737 99.7331,119.6096 100.694,120.2379 101.3777,121.0139 101.7843,121.9379 102.2093,122.8434 102.422,123.8873 102.422,125.07 102.422,126.6222 102.2186,127.9066 101.8121,128.9229 101.424,129.9208 100.8144,130.8079 99.9829,131.584 99.0774,132.4156 97.9776,132.9886 96.684,133.3027 95.3905,133.5984 93.8751,133.7463 92.138,133.7463L86.5946,133.7463 86.5946,118.1683Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
